/* @format */
import React from "react";
//import { Link } from "gatsby";

import Layout from "../../components/layout";
import ToolbarHeader from "../../components/ToolbarHeader";
import Seo from "../../components/seo";

import { useCommonStyles } from "../../styles";

const IndexPage = () => {
    const classes = useCommonStyles();
    return (
        <Layout>
            <Seo title="Spiel und Spaß" />
            <ToolbarHeader title="Spiele" />
            <main className={classes.content}>
                <p>Inhalt kommt....</p>
            </main>
        </Layout>
    );
};

export default IndexPage;
